//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyMessage from '@/components/EmptyMessage'
export default {
    name: 'GroupList',
    components: {
        EmptyMessage,
        GroupMicroView: () => import('@/components/Group/GroupMicroView.vue'),
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
    },
    metaInfo(){
        return{
            title: 'My Groups'
        }
    },
    data(){
        return {
            isEmpty: false,
            isLoading: true,
            pagination: '',
            groups: null,
        }
    },
    created() {
        this.getGroupList(this.$route.params.user);
    },
    methods: {
       getGroupList(id){
            this.$store.dispatch('group/fetchgrouplist', id)
            .then((r) =>{
                if(r.status == 'success'){
                    this.groups = r.groups
                    this.pagination = r.pagination
                }else{
                    this.isEmpty = true
                }
                this.isLoading = false;
            })
        },
    }
}
